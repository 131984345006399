const navButton = $("#navButton");
const closeButton = $("#closeNav");
const mobileNav = $("#mobileNav");
const body = $("body");

function hideNav() {
  console.log("hideNav");
  mobileNav.fadeOut(200);
  navButton.removeAttr("aria-expanded");
  body.removeClass("nav-expanded");
}

function showNav() {
  console.log("showNav");
  mobileNav.css("display", "flex");
  mobileNav.hide();
  mobileNav.fadeIn(200);
  navButton.attr("aria-expanded", "true");
  body.addClass("nav-expanded");
}

function toggleNav() {
  if (body.hasClass("nav-expanded")) {
    hideNav();
  } else {
    showNav();
  }
}

navButton.on("click", function () {
  toggleNav();
});
